import cls from '@/helpers/cls';
import { HTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';

export default function InputError({
  message,
  className = '',
  light,
  ...props
}: HTMLAttributes<HTMLParagraphElement> & { message?: string | FieldError; light?: boolean }) {
  const messageText = typeof message === 'string' ? message : message?.message;

  return messageText ? (
    <p {...props} className={cls('mt-1 text-sm text-red-600', light && 'rounded-lg bg-subtle-green px-2 py-3', className)}>
      {messageText}
    </p>
  ) : null;
}
